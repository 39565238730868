<template>
  <b-modal
    id="funding-modal"
    :title="
      readonly
        ? 'عرض بيانات التمويل'
        : form.id
        ? 'تحديث بيانات التمويل'
        : 'أضافة تمويل'
    "
    shadow
    hide-footer
    :no-close-on-backdrop="true"
    @hidden="clearForm"
    size="xl"
    body-class="p-5"
  >
    <VForm v-slot="{ onValidate }">
      <b-row>
        <b-col>
          <VTextField
            v-model="form.name"
            label="عنوان التمويل"
            rules="required"
            :readonly="readonly"
          ></VTextField
        ></b-col>
        <b-col>
          <VTextField
            v-model="form.limits"
            label="حدود التمويل"
            rules="required"
            :readonly="readonly"
          ></VTextField
        ></b-col>
        <b-col>
          <VTextField
            v-model="form.benefit"
            label="نسبة الفائدة"
            rules="required"
            :readonly="readonly"
          ></VTextField
        ></b-col>
      </b-row>

      <b-row>
        <b-col
          ><VTextField
            v-model="form.period"
            label="مدة التمويل"
            rules="required"
            :readonly="readonly"
          ></VTextField
        ></b-col>
        <b-col
          ><VSelectField
            v-model="form.type"
            :items="types"
            label="النوع"
            rules="required"
            :readonly="readonly"
          ></VSelectField
        ></b-col>
      </b-row>

      <b-row>
        <b-col
          ><VTextEditor
            v-model="form.about"
            label="حول التمويل"
            rules="required"
            :readonly="readonly"
          ></VTextEditor
        ></b-col>
        <b-col
          ><VTextEditor
            v-model="form.warranties"
            :items="types"
            label="الضمانات"
            rules="required"
            :readonly="readonly"
          ></VTextEditor
        ></b-col>
      </b-row>

      <b-row>
        <b-col
          ><VTextEditor
            v-model="form.conditions"
            label="الشروط"
            rules="required"
            :readonly="readonly"
          ></VTextEditor
        ></b-col>
        <b-col
          ><VTextEditor
            v-model="form.mechanism"
            label="آلية التمويل"
            rules="required"
            :readonly="readonly"
          ></VTextEditor
        ></b-col>
      </b-row>
      <b-row>
        <b-col
          ><VTextEditor
            v-model="form.payment_mechanism"
            label="آلية الدفع"
            rules="required"
            :readonly="readonly"
          ></VTextEditor
        ></b-col>
      </b-row>
      <b-row class="my-3" v-if="!readonly">
        <b-col>
          <b-button
            :variant="form.id ? 'success' : 'info'"
            block
            @click="onValidate(action)"
            >{{ form.id ? "تحديث" : "أضافة" }}</b-button
          >
        </b-col>
        <b-col>
          <b-button
            variant="outline-info"
            block
            @click="$bvModal.hide('funding-modal')"
            >الغاء</b-button
          >
        </b-col>
      </b-row>
    </VForm>
  </b-modal>
</template>
<script>
import { clearObject, successAlert, errorAlert } from "@/utils/helper";

export default {
  props: { readonly: Boolean },
  computed: {
    form: {
      get() {
        return this.$store.state.banks.funding.form;
      },
      set(value) {
        this.$store.commit("banks/funding/SET_FUNDING", value);
      },
    },
    types() {
      return this.$store.state.banks.funding.types;
    },
  },
  methods: {
    action() {
      var state = this.form.id ? "update" : "store";
      this.$store
        .dispatch(`banks/funding/${state}`)
        .then((response) => {
          successAlert();
          this.$bvModal.hide("funding-modal");
          this.$store.commit(
            `banks/funding/${state.toUpperCase()}_FUNDING`,
            response.payload
          );
        })
        .catch((error) =>
          errorAlert(
            error.response ? error.response.data.message : "حدثت مشكله"
          )
        );
    },
    clearForm() {
      this.form = clearObject(this.form);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss"></style>
